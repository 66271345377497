import axios from "axios";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";
import formValidate from "../mixins/validate";
import manifestInfoTestData from "@/_json/_manifest.json";

export default {
  mixins: [formValidate],
  data() {
    return {
      // API返り値（選択肢などに仕様）
      memberOpt: [],
      memberDisOpt: [],
      jwnetItemsOpt: [],
      recurryingTypesOpt: [],
      routesInfo: [],
      wasteUnitOpt: [],
      confirmMemberOpt: [],
      shapeOpt: [],
      disposalWayTypes: [],
      harmfulTypes: [],
      routeLabels: [],
      wasteNameOpt: [],
      routeDetailInfo: {
        routeId: null,
        generateStoreInfo: {
          id: null,
          name: "",
        },
        routeCollectCompanyInfo: [],
        routeDisposalCompanyInfo: {
          disposalCompanyInfo: {
            id: null,
            name: "",
          },
        },
      },
    };
  },
  methods: {
    //マニフェスト情報API    API_URL.MANIFEST.DETAIL + {id}
    getManifestInfoApi(manifestId) {
      const setData = (res) => {
        this.formValue = res.data;

        while (this.formValue.contactNoInfo.length < 3) {
          let index = this.formValue.contactNoInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
          this.formValue.contactNoInfo.push({ id: index, contactNo: ""});
        }

        while (this.formValue.remarksInfo.length < 5) {
          let index = this.formValue.remarksInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
          this.formValue.remarksInfo.push({ id: index, detail: ""});
        }

        this.formValue.wasteInfo.disposalWayTypeId = res.data.wasteInfo.disposalWayType ? res.data.wasteInfo.disposalWayType.id : null;
        this.validate.contactNo = [];
        if (res.data.contactNoInfo && res.data.contactNoInfo.length > 0) {
          res.data.contactNoInfo.forEach(() => {
            this.validate.contactNo.push({
              isValid: false,
              errMsg: ""
            });
          });
        }
        this.validate.remarks = [];
        if (res.data.remarksInfo && res.data.remarksInfo.length > 0) {
          res.data.remarksInfo.forEach(() => {
            this.validate.remarks.push({
              isValid: false,
              errMsg: ""
            });
          });
        }
        // ルート用
        const r = this.formValue.routeInfo;
        this.routeTempVal = `${r.routeId}-${r.generateStore.id}-${r.collectCompanys[0].id}-${r.disposalSite.id}`;
        return res;
      }

      // テストデータ使用する場合
      if (process.env.VUE_APP_DATA_TYPE === "test") {
        console.log(manifestInfoTestData)
        // return new Promise(resolve => { resolve(setData({data: manifestInfoTestData})) })
      }
      
      return axios
        .get(`${API_URL.MANIFEST.DETAIL}${manifestId}`)
        .then((res) => {
          return setData(res);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    //担当者一覧API    API_URL.MANIFEST.MEMBER_OPT
    getMemberOptApi(generateStoreId) {
      axios
        .get(API_URL.MANIFEST.MEMBER_OPT + generateStoreId)
        .then((res) => {
          this.memberOpt = res.data.MemberOptions;
          this.memberDisOpt = res.data.MemberOptions;
          // 削除されたアカウント、または自分が見えないアカウント（上位の階層）でも選択できるようにする
          if (this.formValue.manifestInfo.registMember && this.formValue.manifestInfo.registMember.id && !this.memberOpt.map(it => it.id).includes(this.formValue.manifestInfo.registMember.id)) {
            this.memberOpt.push({
              id: this.formValue.manifestInfo.registMember.id,
              name: this.formValue.manifestInfo.registMember.name
            });
          }
          if (this.formValue.manifestInfo.generateMember && this.formValue.manifestInfo.generateMember.id && !this.memberDisOpt.map(it => it.id).includes(this.formValue.manifestInfo.generateMember.id)) {
            this.memberDisOpt.push({
              id: this.formValue.manifestInfo.generateMember.id,
              name: this.formValue.manifestInfo.generateMember.name
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当者一覧API（排出）    API_URL.MANIFEST.MEMBER_OPT
    getDisMemberOptApi() {
      axios
        .get(API_URL.MANIFEST.MEMBER_OPT)
        .then((res) => {
          this.memberDisOpt = res.data.MemberOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類選択肢取得API    API_URL.DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 繰り返し情報選択肢取得API     API_URL.DOMAIN.RECURRING
    getRecurryingApi() {
      axios
        .get("/v_resources/data/json/recurryingType.json")
        .then((res) => {
          this.recurryingTypesOpt = res.data.recurringTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート選択肢取API     API_URL.ROUTE.LIST + {generateStoreId}
    getRouteListApi(generateStoreId) {
      axios
        .get(`${API_URL.ROUTE.LIST}${generateStoreId}`)
        .then((res) => {
          this.routesInfo = res.data.routeList;
          this.narrowedRoutesList = [...this.routesInfo];
          this.getRouteOptions();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 数量確定者選択肢取得API    API_URL.MANIFEST.CONFIRM_MEMBER
    getConfirmMemberApi(routeId) {
      axios
        .get(`${API_URL.MANIFEST.CONFIRM_MEMBER}${routeId}`)
        .then((res) => {
          this.confirmMemberOpt = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 荷姿選択肢取得API    API_URL.DOMAIN.SHAPE
    getShapeApi() {
      axios
        .get(API_URL.DOMAIN.SHAPE)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート荷姿選択肢取得API
    getRouteShapeTypeApi(routeId) {
      return axios
          .get(`${API_URL.ROUTE.ROUTE_SHAPE_TYPE}${routeId}`)
          .then((res) => {
            this.shapeOpt = res.data.shapeTypeList;
            // 委託契約から削除した場合選択肢として出す
            if (!res.data.shapeTypeList.some(item => item.id === this.formValue.wasteInfo.shapeType.id)) {
              this.shapeOpt.push({
                "id": this.formValue.wasteInfo.shapeType.id,
                "name": this.formValue.wasteInfo.shapeType.name
              })
            this.shapeOpt.sort((a, b) => (a.id > b.id) ? 1 : -1)
            } 
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getHarmfulTypesApi() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルートラベル取得API    API_URL.ROUTE.LABEL_DETAIL/{id}
    getRouteLabelsApi() {
      const routeId = this.formValue.manifestInfo.routeId;
      return axios
        .get(`${API_URL.ROUTE.LABEL_DETAIL}${routeId}`)
        .then((res) => {
          this.routeLabels = res.data.routeLabels;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // CBA品目別廃棄物名称選択肢取得API    API_URL.DOMAIN.WASTE_NAME_CBA_ITEM
    getWasteNameCBAItemApi(cbaItemId) {
      axios
        .get(API_URL.DOMAIN.WASTE_NAME_CBA_ITEM(cbaItemId))
        .then((res) => {
          this.wasteNameOpt = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ルート情報API API_URL.ROUTE.DETAIL/{routeId}
    getRouteInfoApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          this.routeDetailInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWDSDownloadApi(routeId, wdsFileName) {
      return axios
        .get(`${API_URL.MANIFEST.WDS_DOWNLOAD}${routeId}`, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    postDeliveryChecksheetApi(manifestId) {
      return axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET, {
          manifestIdList: [{ manifestId: manifestId }],
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 処分方法選択肢取得API: ルートに紐づく処分方法を取得   DOMAIN.DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi(routeId) {
      return axios
        .get(`${API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE}${routeId}`)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
          // 委託契約から削除した場合選択肢として出す
          if (!res.data.disposalWayTypeList.some(item => item.id === this.formValue.wasteInfo.disposalWayTypeId)) {
            this.disposalWayTypes.push({
              "id": this.formValue.wasteInfo.disposalWayTypeId,
              "name": !this.formValue.wasteInfo.disposalWayType ? '' : this.formValue.wasteInfo.disposalWayType.name
            })
            this.disposalWayTypes.sort((a, b) => (a.id > b.id) ? 1 : -1)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
